import { useState } from "react";
import "./App.css";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const initialState = {
  company: "",
  name: "",
  mail: "",
  phone: "",
  quantity: "",
  message: "",
};
function App() {
  const [formData, setFormData] = useState(initialState);

  const onChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    await emailjs
      .send(
        "service_2b04vp9",
        "template_wsyrl62",
        formData,
        "7XpfyYSHI6aTsoWxc"
      )
      .then(
        function (response) {
          toast.success("Correo enviado exitosamente", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setFormData(initialState);
        },
        function (error) {
          toast.error("No se ha podido enviar el correo", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      );
  };

  return (
    <div className="container px-5">
      <div className="bg-light rounded-3 py-5 px-4 px-md-5 mb-5">
        <div className="text-center mb-5">
          <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
            <i className="bi bi-envelope"></i>
          </div>
          <h1 className="fw-bolder">Solicitar prueba gratuita</h1>
          <p className="lead fw-normal text-muted mb-0">
            Completa el siguiente formulario y ser&aacute;s contactado
          </p>
        </div>
        <div className="row gx-5 justify-content-center">
          <div className="col-lg-8 col-xl-6">
            <form id="form" onSubmit={handleSubmit}>
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  id="company"
                  type="text"
                  placeholder="Enter your name..."
                  data-sb-validations="required"
                  name="company"
                  required
                  value={formData.company}
                  onChange={onChange}
                />
                <label htmlFor="company">Nombre de la empresa</label>
                <div
                  className="invalid-feedback"
                  data-sb-feedback="name:required"
                >
                  El nombre de la empresa es requerido.
                </div>
              </div>
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  id="name"
                  type="text"
                  placeholder="Enter your name..."
                  data-sb-validations="required"
                  name="name"
                  required
                  value={formData.name}
                  onChange={onChange}
                />
                <label>Nombre completo</label>
                <div
                  className="invalid-feedback"
                  data-sb-feedback="name:required"
                >
                  El nombre es requerido.
                </div>
              </div>

              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  id="mail"
                  type="mail"
                  placeholder="name@example.com"
                  data-sb-validations="required,email"
                  name="mail"
                  required
                  value={formData.mail}
                  onChange={onChange}
                />
                <label htmlFor="email">Correo electr&oacute;nico</label>
                <div
                  className="invalid-feedback"
                  data-sb-feedback="email:required"
                >
                  El correo es un campo requerido.
                </div>
                <div
                  className="invalid-feedback"
                  data-sb-feedback="email:email"
                >
                  El correo no es v&aacute;lido.
                </div>
              </div>

              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  id="phone"
                  type="tel"
                  placeholder="(123) 456-7890"
                  data-sb-validations="required"
                  name="phone"
                  required
                  value={formData.phone}
                  onChange={onChange}
                />
                <label htmlFor="phone">N&uacute;mero de tel&eacute;fono</label>
                <div
                  className="invalid-feedback"
                  data-sb-feedback="phone:required"
                >
                  El n&uacute;mero de tel&eacute;fono es requerido.
                </div>
              </div>

              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  id="quantity"
                  type="number"
                  placeholder="(123) 456-7890"
                  data-sb-validations="required"
                  name="quantity"
                  required
                  value={formData.quantity}
                  onChange={onChange}
                />
                <label htmlFor="quantity">Cantidad de personas</label>
                <div
                  className="invalid-feedback"
                  data-sb-feedback="phone:required"
                >
                  La cantidad de personas es requerido.
                </div>
              </div>

              <div className="form-floating mb-3">
                <textarea
                  className="form-control"
                  id="message"
                  type="text"
                  placeholder="Enter your message here..."
                  data-sb-validations="required"
                  name="message"
                  required
                  value={formData.message}
                  onChange={onChange}
                  rows={5}
                  cols={5}
                ></textarea>
                <label htmlFor="message">Mensaje</label>
                <div
                  className="invalid-feedback"
                  data-sb-feedback="message:required"
                >
                  El mensaje es requerido.
                </div>
              </div>

              <div className="d-none" id="submitSuccessMessage">
                <div className="text-center mb-3">
                  <div className="fw-bolder">
                    Informaci&oacute;n enviada correctamente
                  </div>
                </div>
              </div>

              <div className="d-none" id="submitErrorMessage">
                <div className="text-center text-danger mb-3">
                  Error enviando el mensaje.
                </div>
              </div>

              <div className="d-grid">
                <button
                  className="btn btn-primary btn-lg"
                  id="submitButton"
                  type="submit"
                >
                  Enviar formulario
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="row gx-5 row-cols-2 row-cols-lg-4 py-5">
        <div className="col">
          <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
            <i className="bi bi-whatsapp"></i>
          </div>
          <div className="h5 mb-2">Habla con nosotros</div>
          <p className="text-muted mb-0">
            Chatea con uno de nuestros especialistas
            <a
              className="text-muted mb-0"
              href="https://wa.me/50664900753?text=¡Hola+estoy+interesado!"
            >
              +506 6490-0753
            </a>
          </p>
        </div>
        <div className="col">
          <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
            <i className="bi bi-envelope-open"></i>
          </div>
          <div className="h5">Correo Electr&oacute;nico</div>
          <p className="text-muted mb-0">
            <a className="text-muted mb-0" href="mailto:ventas@vikingoz.com">
              ventas@vikingoz.com
            </a>
          </p>
        </div>
        <div className="col">
          <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
            <i className="bi bi-question-circle"></i>
          </div>
          <div className="h5">Centro de soporte</div>
          <p className="text-muted mb-0">
            <a className="text-muted mb-0" href="mailto:soporte@vikingoz.com">
              soporte@vikingoz.com
            </a>
          </p>
        </div>
        <div className="col">
          <div className="feature bg-primary bg-gradient text-white rounded-3 mb-3">
            <i className="bi bi-telephone"></i>
          </div>
          <div className="h5">Ll&aacute;manos</div>
          <p className="text-muted mb-0">
            Ll&aacute;menos durante horario laboral al +506 4000-2899
          </p>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
}

export default App;
